<template>
  <div class="pt-8" id="user-table-component">

    <b-table
      id="user-terms-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      :items="user.accepted_terms"
      head-variant="light"
      ref="userTable"
    >

    </b-table>

    <p class="ml-4" v-if="user.accepted_terms.length === 0">{{ $t('ACCESS.TERMS.NO_TERMS') }}</p>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'user-editor-terms',
  components: {

  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),

  },
  props: ['user','user_id'],
  async mounted() {

  },
  methods: {

    if_null_empty(value) {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  data() {
    return {


      fields: [
        {
          key: 'accepted_at',
          label: this.$t('ACCESS.TERMS.ACCEPTED_AT'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'name',
          label: this.$t('ACCESS.TERMS.NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

      ],
    };
  },
  watch: {


  },

};
</script>
<style lang="scss" scoped>

:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>

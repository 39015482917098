<template>
  <b-modal ref="passwordModal" hide-footer
    :title="$t('USER.VERIFY_PASSWORD')">

    <b-form @submit="on_submit">

      <b-row>
        <b-col>
          <b-form-group
            :label="$t('USER.INPUT_PASSWORD')"
            label-for="member-password">
            <b-form-input autocomplete="off"
                type="password"
                id="user-password"
                v-model="password"
                placeholder=""
            />
            <b-form-invalid-feedback>{{$t('USER.INVALID_PASSWORD')}}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-8 mb-8">
        <b-col lg="6">
          <b-button class="" variant="success" @click="on_submit">{{$t('USER.GET_TOKEN')}}</b-button>
        </b-col>
        <b-col lg="6">
          <b-button class="" variant="outline-secondary" block @click="onCancel"
            >{{$t('COMMON.CANCEL')}}</b-button
          >
        </b-col>
      </b-row>

    </b-form>
  </b-modal>
</template>

<style lang="scss" scoped>
</style>
<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'user-prompt-password-modal',
  data() {
    return {
      password: null
    };
  },
  mixins: [ toasts ],
  props: ['user'],
  emits: ['cancel'],
  async mounted() {},

  methods: {
    show() {
      console.log('showing')
      this.$refs['passwordModal'].show();
    },
    onUpdatePasswordClicked() {
      this.updatePassword(this.password);
      this.$refs['passwordModal'].hide();
    },
    onCancel() {
      this.$refs['passwordModal'].hide();

      this.$emit('cancel');
    },

    async on_submit() {
      try {
        const res = await axios.post(`/access/verify-password`, { user_id: this.user.user_id, password: this.password });

        if (res.status === 201) {
          this.copy_to_clipboard(res.data.refresh_token.refresh_token);

          return;
        }
      }
      catch (err) {
        console.error('on_submit', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.INVALID_PASSWORD'));
    },

    copy_to_clipboard(text) {

      navigator.clipboard.writeText(text).then(
        () => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.COPIED_TO_CLIPBOARD'));
        },
        () => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_COPY_CLIPBOARD'));
        },
      );
    },

    updatePassword(password) {
      const url = this.isProfilePage ? `/member/password/public` : `/member/password/${this.member_id}`;

      axios
        .put(url, { password: password })
        .then(res => {
          if (res.status == 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.PASSWORD_UPDATED'));
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_PASSWORD_UPDATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_PASSWORD_UPDATE'));
        });
    },
  },
  computed: {
    validatePassword() {
      if (!this.password || ((this.password+'').length) < 6) {
        return false;
      }

      return true;
    },

    validatePassword2() {
      return this.password === this.password2;
    },


  }
};
</script>

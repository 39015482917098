<template>
  <div id="token-editor">

    <UserPromptPasswordModal
      ref="prompt-password-modal"
      :user="user"
    />

    <b-row>
      <b-col md="6" sm="8">
        <b-form-group :label="$t('ACCESS.TOKEN.REFRESH_TOKEN')">
          <b-form-input
            v-model="tmp"
            type="password"
            :placeholder="$t('ACCESS.TOKEN.REFRESH_TOKEN')"
            ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2" style="padding-top: 42px;">
        <v-btn
          icon
          color="black"
          @click="copy_refresh_token_clicked"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>

      </b-col>

    </b-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import UserPromptPasswordModal from '@/view/pages/ml/users/components/UserPromptPasswordModal.vue';

export default {
  name: 'token-editor',
  components: {
    UserPromptPasswordModal
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),

  },
  props: ['user'],
  async mounted() {

  },
  methods: {

    copy_refresh_token_clicked() {
      console.log('clicked')
      this.$refs['prompt-password-modal'].show();
    },

  },
  data() {
    return {
      tmp: 'asdasdasdasdasdasdasd',
    };
  },
  watch: {

  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>

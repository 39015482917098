<template>
  <div id="group-editor-modal">

    <b-modal ref="edit-group" :title="group_name" size="lg" hide-footer>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{$t('ACCESS.GROUP.DETAILS')}}</v-tab>
        <v-tab :disabled="group_id === null">{{$t('ACCESS.GROUP.COMPANIES')}}</v-tab>
        <v-tab :disabled="group_id === null">{{$t('ACCESS.GROUP.PAGES')}}</v-tab>
        <v-tab :disabled="group_id === null">{{$t('ACCESS.GROUP.PERMISSIONS')}}</v-tab>

        <v-tab-item>
          <GroupEditorDetails
            class="mt-8"
            :group="group"
            @created="group_created"
            @change="group_changed"
          />
        </v-tab-item>

        <v-tab-item>
          <GroupEditorCompanies
            class="mt-8"
            :group_id="group_id"
          />
        </v-tab-item>

        <v-tab-item>
          <GroupEditorPages
            class="mt-8"
            :group="group"
            @created="created_group_page"
            @deleted="deleted_group_page"
          />
        </v-tab-item>

        <v-tab-item>
          <GroupEditorPermissions
            class="mt-8"
            :group="group"
            :group_id="group_id"
            @permissions_changed="permissions_changed"
          />
        </v-tab-item>

      </v-tabs>

        <RightModalSaveAndCloseButtons
          class="mt-4"
          :text="$t('COMMON.SAVE')"
          :spin="true"
          @clicked="save_group_clicked"
          @close="$refs['edit-group'].hide()"
          ref="saveButton"
        />

    </b-modal>

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { getPageNames } from '@/core/services/pagenames';
import GroupEditorDetails from '@/view/pages/ml/users/components/GroupEditorDetails.vue';
import GroupEditorCompanies from '@/view/pages/ml/users/components/GroupEditorCompanies.vue';
import GroupEditorPages from '@/view/pages/ml/users/components/GroupEditorPages.vue';
import GroupEditorPermissions from '@/view/pages/ml/users/components/GroupEditorPermissions.vue';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'group-editor-modal',
  components: {
    GroupEditorDetails,
    GroupEditorCompanies,
    GroupEditorPages,
    GroupEditorPermissions,
    RightModalSaveAndCloseButtons
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),

    group_name() {
      if (this.group && this.group.id) {
        return this.group.name;
      }

      return this.$t('ACCESS.GROUP.NEW_GROUP');
    }

  },
  props: ['group_id'],
  emits: ['created', 'updated', 'deleted'],
  async mounted() {

  },
  methods: {

    created_group_page(group_id, page_id) {
      this.group.pages.push({
        page_id,
        has_access: true
      });

      this.group = { ...this.group };
    },

    deleted_group_page(group_id, page_id) {
      this.group.pages = this.group.pages.filter(item => item.page_id !== page_id)

      this.group = { ...this.group }
    },

    show() {
      this.$refs['edit-group'].show();
    },

    permissions_changed(permissions) {
      this.group.permissions = permissions;
    },

    async save_group_clicked() {
      if (this.group.id) {
        await this.put_group(this.group);
      }
      else {
        await this.post_group(this.group);
      }

      this.$refs['saveButton'].stop();
    },

    group_changed(group) {
      this.group = { ...group }
    },

    group_created(group) {
      this.$emit('created', group);
    },

    edit_group_clicked(item) {
      this.group = item;
      this.$refs['edit-group'].show();
    },

    delete_group_clicked(item) {
      this.delete_group(item.id);
    },

    new_group_clicked() {
      this.group = {};
      this.$refs['edit-group'].show();
    },

    reset() {

      this.group = {};
    },


    async get_group(id) {
      try {

        if (!id) {
          return;
        }

        const res = await axios.get(`/access/group/${id}`);

        if (res.status === 200) {
          this.group = res.data;

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET'));
      }
    },

    async delete_group(id) {
      try {
        const res = await axios.delete(`/access/group/${id}`);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.DELETED'));
          this.$emit('deleted', id);

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_DELETE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_DELETE'));
      }
    },

    async put_group(group) {
      try {
        const res = await axios.put(`/access/group/${group.id}`, group);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.UPDATED'));

          this.$emit('updated', group);

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_UPDATE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_UPDATE'));
      }
    },

    async post_group(group) {
      try {
        const res = await axios.post(`/access/group`, group);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.CREATED'));

          this.$emit('created', res.data);

          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_CREATE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_CREATE'));
      }
    },
  },
  data() {
    return {
      group: {}
    };
  },
  watch: {
    group_id() {
      this.get_group(this.group_id);
    }
  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>

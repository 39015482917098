<template>
  <div>

    <b-row class="justify-content-end my-4 mx-1">
      <b-button variant="primary" @click="new_term()">(+) {{$t('TAB.USER_TERMS.NEW_TERM')}}</b-button>
    </b-row>

    <terms-table :terms="filtered_terms" @active_changed="active_changed" @edit_term="edit_term" @delete_term="delete_term">
    </terms-table>

    <b-modal ref="edit-term-modal" size="lg" hide-footer>
      <terms-edit :term="local_term" @save_term="save_term" @close="$refs['edit-term-modal'].hide()">
      </terms-edit>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';
import TermsTable from '@/view/components/terms/TermsTable.vue';
import TermsEdit from '@/view/components/terms/TermsEdit.vue';
import { updateTerms } from '@/core/services/initUserMemlist';


export default {

  name: 'Terms',
  mixins: [ toasts ],

  components: {
    TermsTable, TermsEdit
  },

  props: {
    audience: {
      type: String,
      default: 'PUBLIC'
    },
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'terms']),

    filtered_terms() {
      return this.terms.filter(item => item.audience === this.audience);
    }
  },

  watch: {
  },

  methods: {

    new_term() {
      this.local_term = { audience: this.audience };
      this.$refs['edit-term-modal'].show();
    },

    save_term(term) {
      if (!term.id) {
        // create
        axios
          .post(`/company/term/${this.currentCompanyId}`, term)
          .then(res => {
            if (res.status === 201) {
              
              updateTerms(); // updates store to reflect the changes
              
              this.$emit('created', res.data);

              this.local_term = { ...res.data };
              this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.CREATED'));
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_CREATE'));
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_CREATE'));
          });
      }
      else {
        // update
        axios
          .put(`/company/term/${term.id}`, term)
          .then(res => {
            if (res.status === 204) {
              updateTerms(); // updates store to reflect the changes
              this.$emit('updated', term);
              this.local_term = { ...term };
              this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.UPDATED'));
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
            }

          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
          });
      }
    },

    edit_term(term) {

      this.local_term = { ...term }
      
      this.$refs['edit-term-modal'].show();
    },

    delete_term(term) {
      axios
        .delete(`/company/term/${term.id}`)
        .then(res => {
          if (res.status === 200) {
            updateTerms(); // updates store to reflect the changes
            this.local_term = {};

            this.$emit('deleted', term.id)
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.DELETED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_DELETE'));
        });
    },

    active_changed(term) {
      // update
      axios
        .put(`/company/term/${term.id}`, term)
        .then(res => {
          if (res.status === 204) {
            updateTerms(); // updates store to reflect the changes
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.UPDATED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
          }

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
        });
    },

    scroll_to_edit() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];
        if (el) {
          const y = el.getBoundingClientRect().top + window.scrollY -70;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
  },

  mounted() {},

  data() {
    return {
      local_term: {}
    };
  }
};

</script>

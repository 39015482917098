<template>
  <div>

    <div class="p-8">

      <b-row>
        <b-col>
          <tip-label :tip="$t('PAGES.LOGIN.TIP_REDIRECT')">
            <h5 class="mb-4 mt-4">{{$t('PAGES.LOGIN.HEADER_REDIRECT')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-input autocomplete="off"
                id="settings-redirect_no_auth"
                v-model="settings.redirect_no_auth"
              />
          </b-form-group>

        </b-col>
      </b-row>
    </div>

    <div class="p-8">


      <b-row>
        <b-col>
          <tip-label :tip="$t('PAGES.LOGIN.TIP_EMAILPASSWORD')">
            <h5 class="mb-4 mt-4">{{$t('PAGES.LOGIN.HEADER_EMAILPASSWORD')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="8">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-enable_login_email" type="checkbox" name="" v-model="settings.enable_login_email"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('PAGES.LOGIN.CHECKBOX_EMAILPASSWORD') }}</span>
          </div>

        </b-col>
      </b-row>
    </div>


    <div class="p-8">
      <b-row>
        <b-col>
          <tip-label :tip="$t('PAGES.LOGIN.TIP_BANKID')">
            <h5 class="mb-4 mt-4">{{$t('PAGES.LOGIN.HEADER_BANKID')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="8">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input :disabled="!isTHS" id="field-enable_login_bankid" type="checkbox" name="" v-model="settings.enable_login_bankid"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('PAGES.LOGIN.CHECKBOX_BANKID') }}</span>
          </div>

          <p class="mt-4" style="font-style: italic; color: #545454;">{{ $t('PAGES.LOGIN.CONTACT_SUPPORT_BANKID') }}</p>

        </b-col>
      </b-row>



      <b-row class="mt-8">
        <b-col md="8">
          <p class="p-error" v-if="no_login_selected">{{ $t('PAGES.LOGIN.MUST_HAVE_LOGIN_OPTION') }}</p>
          <b-button :disabled="no_login_selected" type="submit" variant="primary" @click.prevent="save_data">{{ $t('COMMON.SAVE') }}</b-button>
        </b-col>
      </b-row>
    </div>

  </div>

</template>

<style lang="scss" scoped>
.p-error {
  color: #ff0000;
  font-weight: bold;
  font-size: 14px;
}
</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {

  name: 'LoginSettings',

  components: {
    TipLabel,
    RequiredFieldsInfo,
    ColorPickerInput
  },

  mixins: [ toasts ],

  computed: {
    no_login_selected() {
      return this.settings.enable_login_bankid === false && this.settings.enable_login_email === false;
    },
    ...mapGetters(['currentCompanyId','isTHS']),

  },

  watch: {

  },

  methods: {
    async load_settings() {
      try {
        const response = await axios.get(`/company/${this.currentCompanyId}`);
        this.settings = response.data.company_settings;
      } catch (error) {
        this.toastr('danger', this.$t('COMMON.ERROR'), error);
      }
    },

    async save_data() {

      try {
        const response = await axios.put(`/company/company_settings/${this.currentCompanyId}`, {
          enable_login_bankid: this.settings.enable_login_bankid,
          enable_login_email: this.settings.enable_login_email,
          redirect_no_auth: this.settings.redirect_no_auth
        });

        if (response.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      }
    },
  },

  mounted() {
    this.load_settings();
  },

  data() {
    return {

      settings: {
        enable_login_bankid: false,
        enable_login_email: false
      }
    };
  }

};

</script>

import { render, staticRenderFns } from "./LoginSettings.vue?vue&type=template&id=0c5bd29a&scoped=true"
import script from "./LoginSettings.vue?vue&type=script&lang=js"
export * from "./LoginSettings.vue?vue&type=script&lang=js"
import style0 from "./LoginSettings.vue?vue&type=style&index=0&id=0c5bd29a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c5bd29a",
  null
  
)

export default component.exports
<template>
  <div id="user-editor-modal">

    <ErrorPopup
      :error="error"
    />

    <b-modal ref="edit-user" size="lg" :ok-title="$t('COMMON.SAVE')" hide-footer>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{$t('USER.DETAILS')}}</v-tab>
        <v-tab :disabled="user.user_id === null">{{$t('USER.TERMS')}}</v-tab>
        <v-tab :disabled="user.user_id === null">{{$t('ACCESS.TOKEN.API_ACCESS')}}</v-tab>

        <v-tab-item class="py-16">

          <UserUpdatePasswordModal
            ref="update-password-modal"
            :user="user"
          />

          <form @submit.prevent="submit_form">

            <b-row>
              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.EMAIL')">
                  <b-form-input
                    v-model="user.email"
                    type="text"
                    :placeholder="$t('USER.EMAIL')"
                    :state="email_state"
                  />
                  <b-form-invalid-feedback>{{ $t('COMMON.INVALID_EMAIL') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>

            </b-row>

            <!--<b-row v-if="user.user_id">
              <b-col md="6">
                <b-button @click="open_update_password" variant="primary">{{ $t('USER.UPDATE_PASSWORD') }}</b-button>
              </b-col>
            </b-row>-->

            <b-row>
              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.ROLE')">
                  <b-form-select
                    v-model="user.role"
                    type="text"
                    :options="role_options"
                    :state="role_state"
                  />
                  <b-form-invalid-feedback>{{ $t('COMMON.INVALID_ROLE') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col md="5" sm="8">
                <b-form-group :label="$t('USER.GROUP')">
                  <b-form-select
                    v-model="user.group_id"
                    type="text"
                    :options="group_options"
                    :state="group_id_state"
                  />
                  <b-form-invalid-feedback>{{ $t('COMMON.INVALID_GROUP') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col md="1" sm="4" style="margin-top: 32px;">
                <a
                  class="btn btn-icon btn-light btn-sm mx-2"
                  v-if="user.group_id"
                  @click.prevent="remove_from_group(user.group_id, user.user_id)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                  </span>
                </a>
              </b-col>

            </b-row>


            <b-row>
              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.FIRSTNAME')">
                  <b-form-input v-model="user.firstname" type="text" :placeholder="$t('USER.FIRSTNAME')"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.LASTNAME')">
                  <b-form-input v-model="user.lastname" type="text" :placeholder="$t('USER.LASTNAME')"></b-form-input>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>

              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.PERSONNR')">
                  <b-form-input v-model="user.in_personnr" type="text" :placeholder="$t('USER.PERSONNR')"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.PHONE')">
                  <b-form-input v-model="user.phone" type="text" :placeholder="$t('USER.PHONE')"></b-form-input>
                </b-form-group>
              </b-col>

            </b-row>

            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="submit_form"
              @close="on_cancel"
              ref="saveButton"
            />

          </form>

        </v-tab-item>

        <v-tab-item class="py-8">

          <UserEditorTerms
            :user="user"
            :user_id="local_user_id"
          />

        </v-tab-item>

        <v-tab-item class="py-8">
          <TokenEditor
            :user="user"
          />
        </v-tab-item>

        
      </v-tabs>

    </b-modal>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import UserEditorTerms from '@/view/pages/ml/users/components/UserEditorTerms.vue';
import TokenEditor from '@/view/pages/ml/users/components/TokenEditor.vue';
import UserUpdatePasswordModal from '@/view/pages/ml/users/components/UserUpdatePasswordModal.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { validate_state } from '@/core/services/validations.service';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'UserEditorModal',
  components: {
    
    UserEditorTerms,
    TokenEditor,
    UserUpdatePasswordModal,
    ErrorPopup,
    RightModalSaveAndCloseButtons
  },
  mixins: [ toasts ],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      user: {
        email: {
          required,
          email,
        },
        role: {
          required,
        },
        group_id: {
          required,
        }

      }
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),
    role_options() {
      return [
        { value: 'ADMIN', text: this.$t('USER.ROLES.ADMIN') },
        { value: 'OWNER', text: this.$t('USER.ROLES.OWNER') },
        { value: 'USER', text: this.$t('USER.ROLES.USER') },
        { value: 'NONE', text: this.$t('USER.ROLES.NONE') },
      ]
    },
    group_options() {
      const options = [];

      for (const group of this.groups) {
        options.push({
          value: group.id,
          text: group.name
        });
      }

      return options;
    },
    email_state() {
      return validate_state({ dirty: this.v$.user.email.$dirty, error: this.v$.user.email.$error });
    },
    role_state() {
      return validate_state({ dirty: this.v$.user.role.$dirty, error: this.v$.user.role.$error });
    },
    group_id_state() {
      return validate_state({ dirty: this.v$.user.group_id.$dirty, error: this.v$.user.group_id.$error });
    },
  },
  props: ['user_id','groups'],
  emits: ['created', 'updated'],
  async mounted() {

  },
  methods: {
    on_cancel() {
      this.$refs['edit-user'].hide();
    },
    open_update_password() {
      this.$refs['update-password-modal'].show();
    },

    async remove_from_group(group_id, user_id) {
      try {
        const result = await axios.delete(`/access/group/${group_id}/user/${user_id}`);

        if (result.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('ACCESS.GROUP.USER_REMOVED'));
          this.user.group_id = null;
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_REMOVE_USER'));
    },

    async submit_form() {
      const is_valid = await this.v$.$validate();

      if (!is_valid) {
        this.v$.$touch();
        return;
      }

      try {
        if (this.user.user_id) {
          await this.put_user(this.user);
        }
        else {
          await this.post_user(this.user);
        }

        this.$refs['edit-user'].hide();
      }
      catch (err) {
        console.error('submit_form error', err);
      }

    },

    show() {
      this.$refs['edit-user'].show();

      this.$nextTick(()=>{
        if (this.local_user_id) {
          this.get_user(this.local_user_id);
        }
      });
    },

    edit_user_clicked(item) {
      this.user = item;
      this.$refs['edit-user'].show();
    },

    new_user_clicked() {
      this.user = {};
      this.$refs['edit-user'].show();
    },

    async get_user(id) {
      try {
        const res = await axios.get(`/access/user/${id}`);

        if (res.status === 200) {
          this.user = res.data;

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }
    },

    async put_user(user) {
      try {
        const res = await axios.put(`/access/user/${user.user_id}`, user);

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('USER.UPDATED'));

          console.log('emitting updated on ', res.data);

          this.$emit('updated', res.data);

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_UPDATE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_UPDATE'));
      }
    },

    async post_user(user) {
      try {
        const res = await axios.post(`/access/user`, user);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('USER.CREATED'));

          this.$emit('created', res.data);

          return res.data;
        }

        else if (res.status === 409) {
          console.log('reason ', res.data.reason);
          if (res.data.reason.indexOf('invite template') >= 0) {

            this.error = 'ERR_INVITE_TEMPLATE_NOT_SET';

            this.$nextTick(()=>{ this.error = null; });

            console.log('error is set', this.error );
          }
          else if (res.data.reason.indexOf('taken') >= 0) {
            this.error = 'ERR_EMAIL_ALREADY_REGISTERED';

            this.$nextTick(()=>{ this.error = null; });

          }
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_CREATE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_CREATE'));
      }
    },

    reset() {
      this.local_user_id = null;
      this.user = {};
    },

  },
  data() {
    return {
      local_user_id: null,
      error: null,
      user: {},
      assigned_group_id: 1,
    };
  },
  watch: {
    user_id(new_val, old_val) {
      this.local_user_id = new_val;
    },
    local_user_id(new_val, old_val) {
      if (new_val !== null) {
        this.get_user(new_val);
      }
    },
  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>

<template>
  <div id="group-editor-permissions">

    <ExpandableTable
      :children="permissions_hierarchy"
      :fields="permissions_fields"
      highlightable

      @content_link_clicked="content_link_clicked"
      @cell_value_changed="permissions_checkbox_changed"
    />

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import ExpandableTable from '@/view/components/tables/ExpandableTable.vue';

export default {
  name: 'group-editor-permissions',
  components: {
    ExpandableTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),
    permissions_fields() {
      return [
        { name: 'name', text: this.$t('COMPANY_PERMISSION.NAME'), cols: 6 },
        { name: 'val1', text: this.$t('COMPANY_PERMISSION.READ'), cols: 2, type: 'checkbox' },
        { name: 'val2', text: this.$t('COMPANY_PERMISSION.ADMIN'), cols: 2, type: 'checkbox' }
      ];
    },
    permissions_hierarchy() {

      const new_permissions = [];

      const member_node = {
        id: 'member',
        name: this.$t('ACCESS.PERMISSIONS.MEMBER.NAME'),
        val1: this.is_all_permissions_for_type_set('MEMBER', 'R'),
        val2: this.is_all_permissions_for_type_set('MEMBER', 'A'),
        children: []
      };


      member_node.children.push(this.make_perm_row('MEMBER.C', this.group_permission_str, this.PERMISSIONS_LOOKUP.MEMBER.C, 'ACCESS.PERMISSIONS.MEMBER.C'));
      member_node.children.push(this.make_perm_row('MEMBER.R', this.group_permission_str, this.PERMISSIONS_LOOKUP.MEMBER.R, 'ACCESS.PERMISSIONS.MEMBER.R'));
      member_node.children.push(this.make_perm_row('MEMBER.U', this.group_permission_str, this.PERMISSIONS_LOOKUP.MEMBER.U, 'ACCESS.PERMISSIONS.MEMBER.U'));
      member_node.children.push(this.make_perm_row('MEMBER.D', this.group_permission_str, this.PERMISSIONS_LOOKUP.MEMBER.D, 'ACCESS.PERMISSIONS.MEMBER.D'));
      member_node.children.push(this.make_perm_row('MEMBER.S', this.group_permission_str, this.PERMISSIONS_LOOKUP.MEMBER.S, 'ACCESS.PERMISSIONS.MEMBER.S'));
      member_node.children.push(this.make_perm_row('MEMBER.PUBLIC_ID', this.group_permission_str, this.PERMISSIONS_LOOKUP.MEMBER.PUBLIC_ID, 'ACCESS.PERMISSIONS.MEMBER.PUBLIC_ID'));


      const user_node = {
        id: 'user',
        name: this.$t('ACCESS.PERMISSIONS.USER.NAME'),
        children: [],
        val1: this.is_all_permissions_for_type_set('USER', 'R'),
        val2: this.is_all_permissions_for_type_set('USER', 'A'),
      };

      user_node.children.push(this.make_perm_row('USER.C', this.group_permission_str, this.PERMISSIONS_LOOKUP.USER.C, 'ACCESS.PERMISSIONS.USER.C'));
      user_node.children.push(this.make_perm_row('USER.R', this.group_permission_str, this.PERMISSIONS_LOOKUP.USER.R, 'ACCESS.PERMISSIONS.USER.R'));
      user_node.children.push(this.make_perm_row('USER.U', this.group_permission_str, this.PERMISSIONS_LOOKUP.USER.U, 'ACCESS.PERMISSIONS.USER.U'));
      user_node.children.push(this.make_perm_row('USER.D', this.group_permission_str, this.PERMISSIONS_LOOKUP.USER.D, 'ACCESS.PERMISSIONS.USER.D'));

      const event_node = {
        id: 'event',
        name: this.$t('ACCESS.PERMISSIONS.EVENT.NAME'),
        children: [],
        val1: this.is_all_permissions_for_type_set('EVENT', 'R'),
        val2: this.is_all_permissions_for_type_set('EVENT', 'A'),
      };


      event_node.children.push(this.make_perm_row('EVENT.C', this.group_permission_str, this.PERMISSIONS_LOOKUP.EVENT.C, 'ACCESS.PERMISSIONS.EVENT.C'));
      event_node.children.push(this.make_perm_row('EVENT.R', this.group_permission_str, this.PERMISSIONS_LOOKUP.EVENT.R, 'ACCESS.PERMISSIONS.EVENT.R'));
      event_node.children.push(this.make_perm_row('EVENT.U', this.group_permission_str, this.PERMISSIONS_LOOKUP.EVENT.U, 'ACCESS.PERMISSIONS.EVENT.U'));
      event_node.children.push(this.make_perm_row('EVENT.D', this.group_permission_str, this.PERMISSIONS_LOOKUP.EVENT.D, 'ACCESS.PERMISSIONS.EVENT.D'));
      event_node.children.push(this.make_perm_row('EVENT.VIEW_PARTICIPANTS', this.group_permission_str, this.PERMISSIONS_LOOKUP.EVENT.VIEW_PARTICIPANTS, 'ACCESS.PERMISSIONS.EVENT.VIEW_PARTICIPANTS'));
      event_node.children.push(this.make_perm_row('EVENT.EDIT_PARTICIPANTS', this.group_permission_str, this.PERMISSIONS_LOOKUP.EVENT.EDIT_PARTICIPANTS, 'ACCESS.PERMISSIONS.EVENT.EDIT_PARTICIPANTS'));


      new_permissions.push(member_node);
      new_permissions.push(user_node);
      new_permissions.push(event_node);

      return new_permissions;
    },
  },
  props: ['group_id', 'group'],
  emits: ['permissions_changed'],
  async mounted() {
    this.group_permission_str = this.group.permissions;
  },
  methods: {

    fill_with_char_until_index(str, index, char) {
      let new_str = str;

      while (new_str.length < index) {
        new_str += char;
      }

      return new_str;
    },

    set_permission(type, action, a, r) {
      const index = this.PERMISSIONS_LOOKUP[type][action];

      this.group_permission_str = this.replaceAt(this.group_permission_str, index, a ? 'A' : r ? 'R' : 'N');

      const perm_string = `${type.toUpperCase()}.${action.toUpperCase()}`;

      return `${perm_string}`;
    },

    replaceAt(str, index, replacement) {
      if (index >= str.length) {

        return str.valueOf();
      }
      return str.substring(0, index) + replacement + str.substring(index + 1);
    },

    async content_link_clicked(name, item, row_index, col_index) {
      console.log('content_link_clicked', name, item, row_index, col_index);
    },

    async permissions_checkbox_changed(value) {

      const payload = [];
      let level = 'N';

      if (value.item.id === 'member' || value.item.id === 'user' || value.item.id === 'event') {
        // set all children to value

        const type = value.item.id.toUpperCase();

        let a = false;
        let r = false;

        if (value.column === 2) {
          a = value.value;
        }
        else {
          r = value.value;
        }

        payload.push(this.set_permission(type, 'C', a, r));
        payload.push(this.set_permission(type, 'R', a, r));
        payload.push(this.set_permission(type, 'U', a, r));
        payload.push(this.set_permission(type, 'D', a, r));

        if (value.item.id === 'member') {
          payload.push(this.set_permission(type, 'S', a, r));
        }
        else if (value.item.id === 'event') {
          payload.push(this.set_permission(type, 'VIEW_PARTICIPANTS', a, r));
          payload.push(this.set_permission(type, 'EDIT_PARTICIPANTS', a, r));
        }

        level = value.column === 2 ? value.value ? 'A' : 'N' : value.value ? 'R' : 'N';


      }
      else {
        level = value.column === 2 ? value.value ? 'A' : 'N' : value.value ? 'R' : 'N';
        this.group_permission_str = this.replaceAt(this.group_permission_str, value.item.id, level);

        payload.push(`${value.item.dbid}`);

      }

      this.set_permissions(payload, level);

    },

    get_permission_at(permission_str, type, action) {
      const index = this.PERMISSIONS_LOOKUP[type][action];
      const check = permission_str.substring(index, index + 1);
      return check;
    },

    is_all_permissions_for_type_set(type, level) {

      if (!(type in this.PERMISSIONS_LOOKUP)) {
        console.error('type ', type, ' not found in PERMISSIONS_LOOKUP');
        return false;
      }

      for (const action in this.PERMISSIONS_LOOKUP[type.toUpperCase()]) {
        const set_level = this.get_permission_at(this.group_permission_str, type, action);

        if (set_level !== level) {
          return false;
        }
      }

      return true;
    },

    async set_permissions(permissions, level) {
      try {
        const res = await axios.put(`/access/group/${this.group_id}/permissions`, { grant_level: level, group_access_permission_strings: permissions });

        if (res.status === 204) {
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.PERMISSIONS.UNABLE_SET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.PERMISSIONS.UNABLE_SET'));
      }
    },

    make_perm_row(dbid, str, index, lang_code) {

      const perm = str[index];
      const label = this.$t(lang_code);

      return {
        id: index,
        dbid: dbid,
        name: label,
        val1: perm === 'R',
        val2: perm === 'A'
      };
    }

  },
  data() {
    return {

      group_permission_str: '',

      PERMISSIONS_LOOKUP: {
        MEMBER: {
          C: 0,
          R: 1,
          U: 2,
          D: 3,
          S: 4,
          PUBLIC_ID: 5
        },
        USER: {
          C: 16,
          R: 17,
          U: 18,
          D: 19
        },
        EVENT: {
          C: 32,
          R: 33,
          U: 34,
          D: 35,
          VIEW_PARTICIPANTS: 36,
          EDIT_PARTICIPANTS: 37,
        },
      }

    };
  },
  watch: {

    group_id(value) {
      this.group_permission_str = this.group.permissions;
    },

    async group_permission_str(value) {
      this.$emit('permissions_changed', value);
    }
  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>

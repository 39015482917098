<template>
  <div class="" id="page-users">
    <h5 class="m-4">{{$t('USER.USERS')}}</h5>

    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('USER.CONFIRM_DELETE')"
      ref="deleteUserConfirm"
      type="success"
      @confirm="onDeleteUserConfirmed"
    />

    <b-card>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{$t('USER.TAB_USERS')}}</v-tab>
        <v-tab>{{$t('USER.TAB_GROUPS')}}</v-tab>
        <v-tab>{{$t('USER.TAB_TERMS')}}</v-tab>
        <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>


        <v-tab-item>
          <TabUsers />
        </v-tab-item>

        <v-tab-item>
          <TabGroups />
        </v-tab-item>

        <v-tab-item>
          <TabTerms />
        </v-tab-item>

        <v-tab-item>
          <TabUserSettings />
        </v-tab-item>

      </v-tabs>
    </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TabGroups from '@/view/pages/ml/users/tabs/TabGroups.vue';
import TabTerms from '@/view/pages/ml/users/tabs/TabTerms.vue';
import TabUsers from '@/view/pages/ml/users/tabs/TabUsers.vue';
import TabUserSettings from '@/view/pages/ml/users/tabs/TabUserSettings.vue';

import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'users',
  components: {
    TabGroups,
    TabTerms,
    TabUsers,
    Confirm,
    TabUserSettings
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'sid', 'isTHS'])
  },
  async mounted() {

  },
  data() {
    return {

    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },

  },
  methods: {

  }
};
</script>

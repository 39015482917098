<template>
  <div id="group-editor-details">
    <ErrorPopup
      :error="error_popup_message"
    />
    
    <b-row>
      <b-col md="6" sm="12">
        <b-form-group :label="$t('ACCESS.GROUP.NAME')">
          <b-form-input @change="$emit('change', local_group)" v-model="local_group.name" type="text" :placeholder="$t('ACCESS.GROUP.NAME')"></b-form-input>
        </b-form-group>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import ErrorPopup from '@/view/components/ErrorPopup.vue';

export default {
  name: 'GroupEditorDetails',
  components: {
    ErrorPopup
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),

  },
  props: ['group'],
  emits: ['created', 'updated', 'deleted', 'change'],
  async mounted() {
    if (this.group) {
      this.local_group = { ...this.group };
    }
  },
  methods: {
    save_group_clicked() {

      if (this.local_group.id) {
        this.put_group(this.local_group);
      }
      else {
        this.post_group(this.local_group);
      }
    },

    edit_group_clicked(item) {
      this.local_group = item;
      this.$refs['edit-group'].show();
    },

    delete_group_clicked(item) {
      this.delete_group(item.id);
    },

    new_group_clicked() {
      this.local_group = {};
      this.$refs['edit-group'].show();
    },

    async delete_group(id) {
      try {
        const res = await axios.delete(`/access/group/${id}`);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.DELETED'));
          this.$emit('deleted', id);

          return;
        }
        else if (res.status === 409) {
          this.error_popup_message = 'ERR_409_GROUP';
          this.$nextTick(()=>{ this.error_popup_message = null; });
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_DELETE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_DELETE'));
      }
    },

    async put_group(group) {
      try {
        const res = await axios.put(`/access/group/${group.id}`, group);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.UPDATED'));

          this.$emit('updated', group);

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_UPDATE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_UPDATE'));
      }
    },

    async post_group(group) {
      try {
        const res = await axios.post(`/access/group`, group);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.CREATED'));

          this.$emit('created', res.data);

          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_CREATE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_CREATE'));
      }
    },
  },
  data() {
    return {
      local_group: {},
      error_popup_message: null,
    };
  },
  watch: {

    group: {
      deep: true,
      handler(val) {
        this.local_group = { ...val };
      }
    },

  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>

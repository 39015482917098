<template>
  <div class="mx-4">
    <Terms audience="USER"/>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import Terms from '@/view/components/terms/Terms.vue';


export default {

  name: 'TabTerms',
  mixins: [ toasts ],

  components: {
    Terms
  },

  computed: {
    ...mapGetters(['currentCompanyId']),
  },

  watch: {
  },

  methods: {
  },

  mounted() {

  },

  data() {
    return {
    };
  }
};

</script>

<template>
  <b-modal ref="passwordModal" hide-footer
    :title="$t('MEMBER.PASSWORD')">
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('MEMBER.PASSWORD')"
          label-for="member-password">
          <b-form-input autocomplete="off"
              type="password"
              id="member-password"
              v-model="password"
              placeholder=""
              :state="validatePassword"
          />
          <b-form-invalid-feedback>{{$t('MEMBER.PASSWORD_AT_LEAST')}}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('MEMBER.REPEAT_PASSWORD')"
          label-for="member-password2">
          <b-form-input autocomplete="off"
            type="password"
            id="member-password2"
            v-model="password2"
            placeholder=""
            :state="validatePassword2"
          />
          <b-form-invalid-feedback>{{$t('MEMBER.PASSWORDS_DIFFERS')}}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>

    </b-row>
    <b-row class="mt-8 mb-8">
      <b-col>

        <RightModalSaveAndCloseButtons
          :text="$t('COMMON.SAVE')"
          :spin="true"
          @clicked="onUpdatePasswordClicked"
          @close="$refs['passwordModal'].hide()"
          ref="saveButton"
        />

      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss" scoped>
</style>
<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'user-update-password-modal',
  data() {
    return {
      password: null,
      password2: null
    };
  },

  components: {
    RightModalSaveAndCloseButtons
  },
  mixins: [ toasts ],
  props: ['user','is_me'],
  emits: ['cancel'],
  async mounted() {
    this.password = null;
    this.password2 = null;
  },
  methods: {
    show() {
      this.$refs['passwordModal'].show();
    },
    async onUpdatePasswordClicked() {
      await this.updatePassword(this.password);
      this.$refs['saveButton'].stop();
      this.$refs['passwordModal'].hide();
    },
    onCancel() {
      this.$refs['passwordModal'].hide();

      this.$emit('cancel');
    },

    async updatePassword(password) {

      try {

        let res = null;

        if (this.is_me) {
          res = await axios.put(`/access/user/me`, { password: password });
        }
        else {
          res = await axios.post(`/access/user/password`, { user_id: this.user.user_id, password: password });
        }

        if (res.status == 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.PASSWORD_UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_PASSWORD_UPDATE'));
        }

      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_PASSWORD_UPDATE'));
      }

    },


  },
  computed: {
    validatePassword() {
      if (!this.password || ((this.password+'').length) < 6) {
        return false;
      }

      return true;
    },

    validatePassword2() {
      return this.password === this.password2;
    },


  }
};
</script>

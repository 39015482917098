<template>
  <div id="tab-users">

    <UserEditorModal
      :user_id="user_id"
      :groups="groups"
      @updated="user_updated"
      @created="user_created"
      ref="user-editor-modal"
    />


    <UserTable
      class="mt-8"
      :items="users"
      @delete="user_delete_clicked"
      @create="user_create_clicked"
      @selected="user_select_clicked"
    />

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import UserTable from '@/view/pages/ml/users/components/UserTable.vue';
import UserEditorModal from '@/view/pages/ml/users/components/UserEditorModal.vue';

export default {
  name: 'tab-users',
  components: {
    UserTable,
    UserEditorModal
  },
  emits: ['deleted'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),

  },
  async mounted() {
    const users = await this.get_users();

    this.users = users;
    this.groups = await this.get_groups();
  },
  data() {
    return {
      user_id: null,
      users: [],
      groups: [],
    };
  },
  watch: {

  },
  methods: {

    async get_groups() {
      try {
        const res = await axios.get(`/access/groups`);

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET'));
      }

      return [];
    },

    async get_users() {
      try {
        const res = await axios.get(`/access/users`);

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }

      return [];
    },

    user_updated(user) {
      console.log('user updated', user);
      const index = this.users.findIndex(g => g.user_id === user.user_id);

      this.users[index] = user;
      // Vue3 compatability for Vue.set
      this.users = [...this.users];
    },

    async user_checkbox_changed(value) {
      console.log('user_checkbox_changed', value);
    },

    async content_link_clicked(name, item, row_index, col_index) {
      console.log('content_link_clicked', name, item, row_index, col_index);
    },


    async user_select_clicked(item) {

      this.groups = await this.get_groups();

      this.user_id = item.user_id;

      this.$nextTick(()=>{

        this.$refs['user-editor-modal'].show();
      });


    },

    async user_create_clicked() {
      console.log('user_create_clicked 1');

      this.groups = await this.get_groups();

      this.user = {};
      this.user_id = null;

      this.$nextTick(()=>{

        this.$refs['user-editor-modal'].reset();
        this.$refs['user-editor-modal'].show();
      });
    },


    async delete_user(id) {
      try {
        const res = await axios.delete(`/access/user/${id}`);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('USER.DELETED'));
          this.$emit('deleted', id);

          this.user_deleted(id);

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_DELETE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_DELETE'));
      }
    },

    user_delete_clicked(id) {
      this.delete_user(id);
    },

    user_deleted(id) {
      this.users = this.users.filter(g => g.user_id !== id);
    },

    user_created(user) {
      this.users.push(user);
    },

  }
};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>

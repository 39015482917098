<template>
  <div id="group-editor-companies">

    <ExpandableTable
      v-if="table_rows"
      class="table-style"
      :children="table_rows"
      :fields="companies_fields"
      highlightable

      @content_link_clicked="content_link_clicked"
      @cell_value_changed="company_checkbox_changed"
    />

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { getPageNames } from '@/core/services/pagenames';
import ExpandableTable from '@/view/components/tables/ExpandableTable.vue';

export default {
  name: 'group-editor-companies',
  components: {
    ExpandableTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),
    companies_fields() {
      return [
        { name: 'name', text: this.$t('COMPANY_PERMISSION.NAME'), cols: 8 },
        { name: 'has_access', text: this.$t('ACCESS.GROUP.HAS_ACCESS'), cols: 2, type: 'checkbox' },
      ];
    },
    table_rows() {
      if (this.group) {
        return [ this.group ];
      }

      return [];
    }
  },
  props: ['group_id'],
  async mounted() {
    this.get_full_hierarchy(this.group_id);
  },
  methods: {
    async content_link_clicked(name, item, row_index, col_index) {
      console.log('content_link_clicked', name, item, row_index, col_index);
    },

    async company_checkbox_changed(value) {
      console.log('company_checkbox_changed', value);

      if (value.row === 0) {
        return;
      }

      if (value.value) {
        await this.add_company(value.item.company_id, this.group_id);
      }
      else {
        await this.remove_company(value.item.company_id, this.group_id);
      }

      this.get_full_hierarchy(this.group_id);
    },

    async get_full_hierarchy(group_id) {
      try {
        const res = await axios.get(`/access/group/${group_id}/hierarchy/full`);

        if (res.status === 200) {

          this.group = res.data;

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET_HIERARCHY'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET_HIERARCHY'));
      }
    },

    async add_company(company_id, group_id) {
      try {
        const res = await axios.post(`/access/group/${group_id}/company/${company_id}`);

        if (res.status === 201) {
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_ADD_COMPANY'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_ADD_COMPANY'));
      }
    },

    async remove_company(company_id, group_id) {
      try {
        const res = await axios.delete(`/access/group/${group_id}/company/${company_id}`);

        if (res.status === 204) {
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_REMOVE_COMPANY'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_REMOVE_COMPANY'));
      }
    },
  },
  data() {
    return {
      group: null
    };
  },
  watch: {

    group_id(newValue, oldValue) {

      if (newValue) {
        this.get_full_hierarchy(newValue);
      }

    }
  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}


:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>

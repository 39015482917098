<template>
  <div id="tab-groups">

    <GroupEditorModal
      :group_id="group_id"
      ref="group-editor-modal"
      @created="group_created"
    />

    <GroupTable
      class="mt-8"
      :items="groups"
      @delete="group_delete_clicked"
      @create="group_create_clicked"
      @selected="group_select_clicked"
    />

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { getPageNames } from '@/core/services/pagenames';

import GroupTable from '@/view/pages/ml/users/components/GroupTable.vue';
import GroupEditorModal from '@/view/pages/ml/users/components/GroupEditorModal.vue';

export default {
  name: 'tab-groups',
  components: {
    GroupTable,
    GroupEditorModal
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),
  },
  async mounted() {
    const groups = await this.get_groups();

    this.groups = groups;
  },
  methods: {

    group_select_clicked(item) {
      this.group_id = item.id;

      this.$refs['group-editor-modal'].show();
    },

    group_create_clicked() {

      this.group = {};
      this.group_id = null;

      this.$nextTick(()=>{
        this.$refs['group-editor-modal'].reset();
        this.$refs['group-editor-modal'].show();
      });

    },

    group_delete_clicked(id) {
      this.delete_group(id);
    },

    group_deleted(id) {
      this.groups = this.groups.filter(g => g.id !== id);
    },

    group_created(group) {
      this.group_id = group.id;
      this.groups.push(group);
    },

    group_updated(group) {
      const index = this.groups.findIndex(g => g.id === group.id);

      this.groups[index] = group;

      this.groups = [ ...this.groups ];
    },

    async group_checkbox_changed(value) {

    },

    async content_link_clicked(name, item, row_index, col_index) {

    },

    async get_groups() {
      try {
        const res = await axios.get(`/access/groups`);

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET'));
      }

      return [];
    },

    async delete_group(id) {
      try {
        const res = await axios.delete(`/access/group/${id}`);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.DELETED'));

          this.group_deleted(id);

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_DELETE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_DELETE'));
      }
    },

    async put_group(group) {
      try {
        const res = await axios.put(`/access/group/${group.id}`, group);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.UPDATED'));

          this.group_updated(group);

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_UPDATE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_UPDATE'));
      }
    },

    async post_group(group) {
      try {
        const res = await axios.post(`/access/group`, group);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ACCESS.GROUP.CREATED'));

          this.group_created(res.data);

          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_CREATE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_CREATE'));
      }
    },

  },
  data() {
    return {

      group_id: null,

      groups: [],

      groups_access_list: [
        {
          name: 'Grupp 1',
          user_id: 'a',
          read: false,
          admin: false,
          pages: [],
          permissions: [],
          children: [
            {
              name: 'Riksförbundet',
              company_id: 'c',
              read: false,
              admin: false,
              pages: [],
              permissions: [],
              children: [
                {
                  name: 'Region Uppland',
                  company_id: 'c',
                  read: false,
                  admin: false,
                  pages: [],
                  permissions: [],
                  children: [
                    {
                      name: 'Uppsalakretsen',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    },
                    {
                      name: 'Sala lokalförening',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    },
                    {
                      name: 'Enköpingsföreningen',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    }
                  ],
                },
                {
                  name: 'Region Skåne',
                  company_id: 'd',
                  read: false,
                  admin: false,
                  pages: [],
                  permissions: [],
                  children: [
                    {
                      name: 'Malmö boiis',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    },
                    {
                      name: 'Sölvesborg united',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    },
                    {
                      name: 'Lunds förening',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    }
                  ],
                }

              ]
            },
          ]
        },
        {
          name: 'Grupp 2',
          user_id: 'a',
          read: false,
          admin: false,
          pages: [],
          permissions: [],
          children: [
            {
              name: 'Riksförbundet',
              company_id: 'c',
              read: false,
              admin: false,
              pages: [],
              permissions: [],
              children: [
                {
                  name: 'Region Uppland',
                  company_id: 'c',
                  read: false,
                  admin: false,
                  pages: [],
                  permissions: [],
                  children: [
                    {
                      name: 'Uppsalakretsen',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    },
                    {
                      name: 'Sala lokalförening',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    },
                    {
                      name: 'Enköpingsföreningen',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    }
                  ],
                },
                {
                  name: 'Region Skåne',
                  company_id: 'd',
                  read: false,
                  admin: false,
                  pages: [],
                  permissions: [],
                  children: [
                    {
                      name: 'Malmö boiis',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    },
                    {
                      name: 'Sölvesborg united',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    },
                    {
                      name: 'Lunds förening',
                      company_id: 'c',
                      read: false,
                      admin: false,
                      pages: [],
                      permissions: [],
                    }
                  ],
                }

              ]
            },
          ]
        }
      ]

    };
  },
  watch: {


  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>
